@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hover {
  background: #1a1c20;
}

.hover:hover {
  background: #212529;
  scale:1.1;
  transition: .2s ease all;
  cursor: pointer;
}

.hoverInput {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.hoverInput:hover {
  border: 1px solid hsl(0, 0%, 70%);
}

/* background: '#111', */
.hovercell {
	background-color: #222;
}

.active,
.hovercell:hover {
	background-color: #333 !important;
}


.side_bar_active {
  background-color: #f52a2a;
}


.modal {
  position: fixed;
  left: 0;
  top: 20;
  right: 0;
  bottom: 0;
  background-color: #212529;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 999;
}

.modal.show {
  opacity: 1;
  pointer-events: visible;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 40%;
  position: fixed;
  top: 0;
  background-color: #212529;
  color: white;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {}

.modal-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  margin: 0;
  font-size: 24px;
}

.modal-body {
  padding: 20px;
  border-top: 1px solid hsl(0, 0%, 20%);

}

.custom-clock {
  border-radius: 50%;
  background-color: #222;
}

.react-clock__face {
  border: 2px solid #ea6a47 !important;
}

.react-clock__hand__body {
  background-color: #ea6a47 !important;
}


.ais-SearchBox-form {
  background: none;
}

.ais-SearchBox-input {
  background-color: #1a1c20;
  outline: none;
  color: #eee;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: none
}

.ais-SearchBox-input:focus {
  border: 1px solid #ea6a47
}

.ais-SearchBox-input:hover {
  border: 1px solid #ea6a47
}

.search_box {
  width: 400px;
}

.ais-Hits-item {
  height: 100%;
  padding:0;
  border: none;
  background: #888;
  margin:0
}