.html{
  scroll-behavior: smooth;
  height:100%;
}

@font-face {
  font-family: 'digital';
  src: url('./assets/fonts/digital.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'digital',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'AntDesign';
  src: url('./assets/fonts/AntDesign.ttf') format('truetype');
}


@font-face {
  font-family: 'BootstrapIcons';
  src: url('./assets/fonts/bootstrap-icons.woff') format('woff');
}


@font-face {
  font-family: 'Entypo';
  src: url('./assets/fonts/Entypo.ttf') format('truetype');
}


@font-face {
  font-family: 'EvilIcons';
  src: url('./assets/fonts/EvilIcons.ttf') format('truetype');
}


@font-face {
  font-family: 'Feather';
  src: url('./assets/fonts/Feather.ttf') format('truetype');
}


@font-face {
  font-family: 'FontAwesome';
  src: url('./assets/fonts/FontAwesome.ttf') format('truetype');
}


@font-face {
  font-family: 'FontAwesome5Brands';
  src: url('./assets/fonts/FontAwesome5_Brands.ttf') format('truetype');
}


@font-face {
  font-family: 'FontAwesome5_Regular';
  src: url('./assets/fonts/FontAwesome5_Regular.ttf') format('truetype');
}


@font-face {
  font-family: 'FontAwesome5';
  src: url('./assets/fonts/FontAwesome5_Solid.ttf') format('truetype');
}


@font-face {
  font-family: 'Fontisto';
  src: url('./assets/fonts/Fontisto.ttf') format('truetype');
}


@font-face {
  font-family: 'Foundation';
  src: url('./assets/fonts/Foundation.ttf') format('truetype');
}


@font-face {
  font-family: 'Ionicons';
  src: url('./assets/fonts/Ionicons.ttf') format('truetype');
}


@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url('./assets/fonts/MaterialCommunityIcons.ttf') format('truetype');
}


@font-face {
  font-family: 'MaterialIcons';
  src: url('./assets/fonts/MaterialIcons.ttf') format('truetype');
}


@font-face {
  font-family: 'Octicons';
  src: url('./assets/fonts/Octicons.ttf') format('truetype');
}


@font-face {
  font-family: 'SimpleLineIcons';
  src: url('./assets/fonts/SimpleLineIcons.ttf') format('truetype');
}


@font-face {
  font-family: 'Zocial';
  src: url('./assets/fonts/Zocial.ttf') format('truetype');
}

#tooltip-root{
  position: fixed;
  font-size: 12px;
  height:12px;
  line-height: 12px;
  border-radius: 3px;
  padding: 3px 6px;
  background: rgba(0,0,0,0.6);
  color: #fff;
  z-index: 1;
  opacity:0;
  white-space: nowrap;
  text-transform: capitalize;
  pointer-events: none;
}



.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .checkmark {
  background-color: #EF7B4C;
}
.container .checkmark:after {
  left: 4px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.clock {
  margin: 10px;
  border-radius: 10px;
  background: #0d1621;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 10px;
}

.calendar {
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 33px;
  padding: 0 10px;
  padding-top: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.countdown {
  font-size: 7rem;
}

.hour {
  flex: 1;
  font-size: 7rem;
  margin: 0;
  padding: 0;
  top: 0;
}

.ampm {
  align-self: flex-end;
  font-size: 2.5rem;
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
}

.digital {
  display: inline-block;
  position: relative;
  font-family: 'digital';
}

.digital :first-child {
  width: 100%;
  position: absolute;
  color: #1e1e31;
}

.digital :last-child {
  position: relative;
  color: #cfcfcf;
}

.digital p {
  margin: 0;
}
